<template>
  <b-card>
    <g-table
      ref="income-table"
      :items="items"
      :columns="tableColumns"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: true, permission: 'addMaritalStatus'}"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          openModal({ englishName: '' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <b-button
            data-action-type="preview"
            v-b-tooltip.hover.top="$t('preview')"
            variant="custom"
            class="btn-icon"
            size="sm"
            v-permission="'editMaritalStatus'"
            @click="openModal(item)"
          >
            <feather-icon
              icon="EyeIcon"
              class="mx-1 clickable "
              :hidden="true"
              :id="`invoice-row-${item.id}-prev-icon`"
            />
          </b-button>
          <b-button
            data-action-type="edit"
            v-b-tooltip.hover.top="$t('edit')"
            variant="custom"
            class="btn-icon"
            size="sm"
            v-permission="'editMaritalStatus'"
            @click="openModal(item)"
          >
            <feather-icon
              icon="EditIcon"
              class="mx-1 clickable"
              :id="`invoice-row-${item.id}-preview-icon`"
            />
          </b-button>
          <b-button
            data-action-type="delete"
            v-b-tooltip.hover.top="$t('delete')"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            v-permission="'deleteMaritalStatus'"
            @click="remove(item)"
          >
            <feather-icon
              icon="TrashIcon"
              stroke="red"
              class="danger"
              :id="`invoice-row-${item.id}-delete-icon`"
            />
          </b-button>
        </div>
      </template>
    </g-table>
    <b-sidebar
      no-close-on-backdrop
      ref="sidebar"
      id="sidebar-edit"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      left
      @hidden="
        () => {
          selectedItem = {};
        }
      "
    >
    <template #default>
      <div class="d-flex justify-content-between bg-primary align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 text-white">
          {{ selectedItem.id > 0 ? $t('editmaritalStatus') : $t('neWmaritalStatus') }}
        </h5>
      </div>
      <b-card>
      <g-form @submit="save">
        <b-row>
          <b-col md="12">
            <label style="font-size: 14px;margin-bottom: 7px;" for="code">
              {{ $t('code') }}
            </label>
            <g-field
              :value.sync="selectedItem.code"
              id="code"
              type="number"
              name="code"
            >
            </g-field>
          </b-col>

          <b-col md="12">
            <!-- arabicName  -->
            <label style="font-size: 14px;margin-bottom: 7px;" for="arabicName">
              {{ $t('arabicName') }}
            </label>
            <g-field
              :value.sync="selectedItem.arabicName"
              id="arabicName"
              name="arabicName"
              rules="required"
            >
            </g-field>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <label
              style="font-size: 14px;margin-bottom: 7px;"
              for="englishName"
            >
              {{ $t('englishName') }}
            </label>
            <g-field
              ref="englishName"
              :value.sync="selectedItem.englishName"
              id="englishName"
              name="englishName"
            >
            </g-field>
          </b-col>
          <b-col md="3">
            <label
            style="font-size: 14px;margin-bottom: 7px;"
              for="general"
            >
              {{ $t('general') }}
            </label>
            <b-form-group>
              <b-form-checkbox
                v-model="selectedItem.isShared"
                class="mr-0 mt-50"
                name="is-rtl"
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <label style="font-size: 14px;margin-bottom: 7px;" for="customer">
                {{ $t('notes') }}
              </label>
              <b-form-textarea
                id="textarea"
                v-model="selectedItem.notes"
                label="Notes"
                rows="3"
                max-rows="6"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
              class="mx-1"
              type="submit"
              variant="primary"
              data-action-type="save"
              v-permission="$route.meta.permission"
            >
              {{ $t('save') }}
            </b-button>
            <b-button variant="secondary" @click="closeModal">
              {{ $t('Close') }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
      </b-card>
    </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      items: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 25,
      selectedItem: {
        tenantId: 0,
        id: 0,
        code: '',
        arabicName: '',
        englishName: '',
        notes: '',
        accountId: null,
      },
      modal: false,
      Accounts: [],
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        { key: 'arabicName', label: this.$t('name'), sortable: true },
        { key: 'notes', label: this.$t('notes'), sortable: true },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    this.getItems();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getItems() {
      this.get({ url: 'MaritalStatuses' }).then((data) => {
        this.items = data;
      });
    },
    save() {
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'MaritalStatuses',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.getItems();
        });
      } else {
        this.create({ url: 'MaritalStatuses', data: this.selectedItem }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.getItems();
        });
      }
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'MaritalStatuses', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },
    closeModal() {
      this.$refs.sidebar.hide();
    },
    openModal(item) {
      this.selectedItem = { ...item };
      if (item.id) {
        this.get({ url: 'MaritalStatuses', id: item.id }).then((data) => {
          this.selectedItem = data;
        });
      }
      this.$root.$emit('bv::toggle::collapse', 'sidebar-edit')
    },
  },
};
</script>

<style></style>
